.a-loader-title {
  display: hidden;
}

.button-div {
  display: flex;
  justify-content: space-around;
}

.sound-button {
  background-color: #00000000;
  border: none;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-size: 40px;
  padding: 0px;
  margin: auto;
  display: block;
  position: absolute;
  z-index: 1000;
  bottom: 20px;
  right: 30px;
  height: 40px;
}

/* .share-button {
  background-color: #00000000;
  border: none;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-size: 32px;
  padding: 0px;
  margin: auto;
  display: block;
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  left: 20px;
  height: 40px;
} */

.pause-container {
  display: none;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#unmuted-button {
  display: none;
}

#muted-button {
  display: block;
}

.more-button-div {
  position: absolute;
  z-index: 900;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.more-button {
  background-color: #3786f6;
  border: none;
  border-radius: 30px;
  min-width: 120px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
}

.tl-button {
  top: 10px;
  left: 10px;
}

#reticle-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

#reticle-container.hidden {
  display: none;
}

#reticle-container-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

#reticle-container-loading.hidden {
  display: none;
}

#reticle {
  position: relative;
  width: 90vw;
  height: 105vw;
  opacity: 0.8;
  background: linear-gradient(to right, #fff 5px, transparent 5px) 0 0,
    linear-gradient(to right, #fff 5px, transparent 5px) 0 100%,
    linear-gradient(to left, #fff 5px, transparent 5px) 100% 0,
    linear-gradient(to left, #fff 5px, transparent 5px) 100% 100%,
    linear-gradient(to bottom, #fff 5px, transparent 5px) 0 0,
    linear-gradient(to bottom, #fff 5px, transparent 5px) 100% 0,
    linear-gradient(to top, #fff 5px, transparent 5px) 0 100%,
    linear-gradient(to top, #fff 5px, transparent 5px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pack {
  background: #000000;
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  z-index: 2000;
  top: 100%;
  overflow-x: hidden;
  transition: 0.5s;
}

.pack-close {
  background-color: #00000000;
  border: none;
  color: #000000;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 24px;
}

.error-screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: none;
}

.error-message {
  background-color: #000000;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  margin: 60px 20px 60px 20px;
  padding: 20px;
  justify-content: center;
  font-family: 'roboto', 'helvetica', sans-serif;
}

.loader {
  border: 16px solid #222;
  border-top: 16px solid white;
  opacity: 0.8;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
